import { graphql } from "gatsby";
import React, { useState } from "react";
import { MainHeading } from "../components/MainHeading";
import { Page } from "../components/Page";
import { Thumbnail } from "../components/Thumbnail";
import { ThumbnailGrid } from "../components/ThumbnailGrid";
import Lightbox from "react-image-lightbox";

type GalleryProps = {
  data: GatsbyTypes.GalleryQuery;
};

export default function GalleryTemplate({ data }: GalleryProps) {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const images: Array<string> =
    data.galleriesJson?.images?.map((image) => image?.publicURL || "") || [];
  const prevIndex = (currentIndex + images.length - 1) % images.length;
  const nextIndex = (currentIndex + 1) % images.length;

  return (
    <Page>
      <MainHeading>{data.galleriesJson?.name}</MainHeading>
      {isOpen ? (
        <Lightbox
          mainSrc={images[currentIndex]}
          prevSrc={images[prevIndex]}
          nextSrc={images[nextIndex]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setCurrentIndex(prevIndex)}
          onMoveNextRequest={() => setCurrentIndex(nextIndex)}
        />
      ) : null}
      <ThumbnailGrid>
        {data.galleriesJson?.images?.map(
          (image, index) =>
            image?.childImageSharp?.gatsbyImageData && (
              <button
                key={image.id}
                onClick={() => {
                  setCurrentIndex(index);
                  setIsOpen(true);
                }}
              >
                <Thumbnail
                  alt=""
                  imageData={image.childImageSharp.gatsbyImageData}
                />
              </button>
            )
        )}
      </ThumbnailGrid>
    </Page>
  );
}

export const query = graphql`
  query Gallery($slug: String!) {
    galleriesJson(fields: { slug: { eq: $slug } }) {
      category
      name
      images {
        id
        publicURL
        childImageSharp {
          gatsbyImageData(aspectRatio: 1, width: 590, placeholder: NONE)
        }
      }
    }
  }
`;
